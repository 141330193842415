import { css } from '@emotion/css'
import { textColor } from '@pp/common/css/colors'
import { increaseSpecificity } from '@pp/common/css/css.helper'

export const gridCss = css`
  ${increaseSpecificity(`min-height: 500px; padding: 25px !important;`)}
`

export const logoCss = (isMobile: boolean) => css`
  ${increaseSpecificity(`
  width: ${isMobile ? '35%' : '15%'};
  margin-bottom: 8px;
`)}
`

export const ctaButtonCss = css`
  ${increaseSpecificity(`
    padding: 10px 12px !important;
`)}
`

export const usersMessageCss = (isMobile: boolean) => css`
  color: ${textColor};
  font-size: 14px !important;
  font-weight: 400;
  margin-left: 8px;
  margin-top: ${isMobile ? '8px' : '0'};
`

export const buttonRowCss = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const buttonMarginRightCss = css`
  margin-right: 8px !important;
`

export const container = (isMobile: boolean) => ({
  marginTop: isMobile ? '15px' : '65px',
})

export const styles = {
  title: {
    color: textColor,
    fontSize: '16px',
    fontWeight: '300 !important',
    margin: '0 0 16px 0',
  },
  row: { display: 'flex', alignItems: 'flex-start', margin: '14px 0' },
  checkbox: { marginTop: 3, marginRight: 8 },
  paragraph: { color: textColor, fontSize: '16px' },
  title1: { fontWeight: 600, color: '#FFBF00' },
  title2: { fontWeight: 600, color: '#96C347' },
  title3: { fontWeight: 600, color: '#85C1E9' },
}
