import { css } from '@emotion/css'
import { textColor } from '@pp/common/css/colors'
import { increaseSpecificity } from '@pp/common/css/css.helper'

export const modalCss = (isMobile: boolean) => css`
  ${increaseSpecificity(`
  ${isMobile && 'width: 80%;'}
`)}
`

export const gridCss = css`
  ${increaseSpecificity(`min-height: 500px; padding: 16px !important; padding-left: 32px !important;`)}
`

export const messageCss = (isMobile: boolean) => css`
  width: ${isMobile ? '100%' : '50% !important'};
  margin: auto !important;
  ${isMobile ? 'margin-top: 30px !important' : 'margin-top: 130px !important'};
  background-color: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  border: 1px solid #e6e6e6 !important;
`

export const rowCss = css`
  padding: 24px;
  height: 100%;
  color: black;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const logoCss = css`
  ${increaseSpecificity(`
  width: 15%;
  margin-bottom: 8px;
`)}
`

export const listCss = css`
  padding: 0;
  margin: 0;
  font-size: 14px;
`

export const listItemCss = css`
  display: flex;
  justify-content: start;
  flex-direction: row;
  padding: 10px 0;
`

export const itemIconCss = css`
  width: 25px;
  height: 25px;
  margin-right: 25px;
`

export const imageCss = css`
  margin: 20px 35px 20px 0;
`

export const image2Css = css`
  margin: 30px 20px 10px 0;
`

export const titleCss = (isMobile: boolean) => css`
  font-size: ${isMobile ? '15px' : '20px'};
  text-transform: uppercase;
  text-align: center;
  color: #999999;
`

export const title2Css = (isMobile: boolean) => css`
  font-size: ${isMobile ? '15px' : '18px'};
  text-transform: uppercase;
  text-align: center;
  color: ${textColor};
`

export const flexCss = css`
  ${increaseSpecificity(`
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
  `)}
`

export const leftColumnCss = (isMobile: boolean) => css`
  background-color: #ffffff;
  border-radius: ${isMobile ? '5px 5px 0 0' : '5px 0 0 5px'};
`

export const rightColorCss = (isMobile: boolean) => css`
  background-color: white;
  border-radius: ${isMobile ? '0 0 5px 5px' : '0 5px 5px 0'};
`

export const learnMoreCss = (isMobile: boolean) => css`
  ${isMobile && 'margin-top: 5%'};
  text-align: center;
  font-size: 14px;
`

export const loginTextCss = (isMobile: boolean) => css`
  text-align: center;
  color: #c1540b;
  font-size: ${isMobile ? '14px' : '14px'};
  ${isMobile && 'margin-top: 8%; margin-bottom: 2%'};
`

export const buttonCss = css`
  ${increaseSpecificity(`
  margin: 10px;
`)}
`
