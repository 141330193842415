export type Kpi = { count: number | string; kpi: number | string }
export type WhitespotApplicant = { count: number; country: string; name: string; urlIdentifier: string }
export type WhitespotDrilldownApplicant = WhitespotApplicant & { ipc_8: string }
export type ApplicantsSortObject = { [key: string]: SortOrder }
// export type WhitespotResult = CommonWhitespotResult & { ipc_4: string }
// export type WhitespotDrilldownResult = CommonWhitespotResult & { ipc_8: string }
export type PreparedRows = Array<{
  color?: string
  focal_case_count: number
  key: string
  target_case_count: number
  tech_overlap_count: number
}>

export enum SortOrder {
  'asc' = 'asc',
  'desc' = 'desc',
}

export type SortObject = {
  [key: string]: SortOrder | undefined
  focal_case_count?: SortOrder
  focal_rank?: SortOrder
  target_case_count?: SortOrder
  target_rank?: SortOrder
  tech_overlap?: SortOrder
  tech_overlap_count?: SortOrder
}

export type WhiteSpotMappedApplicant = {
  [key: string]: (WhitespotApplicant & { profile: boolean }) | number
  applicant: WhitespotApplicant & { profile: boolean }
  biggestValue: number
  sum: number
}

export type TechOverlapResult = {
  color?: string
  focal_applicants: WhitespotApplicant[]
  focal_case_count: number
  focal_rank: number
  key: string
  target_applicants: WhitespotApplicant[]
  target_case_count: number
  target_rank: number
  tech_overlap: number
  tech_overlap_count: number
}

export type PreparedResults = {
  appHeader: string[]
  focalApplicants: WhiteSpotMappedApplicant[]
  header: Array<{ key: string; name: string }>
  rows: PreparedRows
  targetApplicants: WhiteSpotMappedApplicant[]
}

export type PreparedDrilldownResults = {
  focalApplicants: WhitespotDrilldownApplicant[]
  header: Array<{ key: string; name: string }>
  rows: PreparedRows
  targetApplicants: WhitespotDrilldownApplicant[]
}
