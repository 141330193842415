import { EntitySearchResult } from '@pp/common/components/entity-name-search/entity-search.interface'
import { arrayFromString } from '@pp/common/helpers'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { RootStoreInterface } from '@pp/store/root.store'
import _ from 'lodash'
import { makeAutoObservable } from 'mobx'

import {
  FLimitingClauseType,
  RadiusUnit,
  RadiusValue,
  SearchParameters,
} from '../../../../search/advanced-search/typescript/search.interface'
import { LawFirmResults } from '../../common/components/SimilarityUiState'

export type ServerFilters = {
  date_from: string[]
  date_to: string[]
  date_type: DateType[]
  family_type: FamilyType[]
  focal_rep_url: string[]
  target_ipc_1?: string[]
  target_ipc_3?: string[]
  target_ipc_4?: string[]
  target_ipc_8?: string[]
  target_ipc_class?: string[]
  target_relevant_count_from?: number[]
  target_relevant_count_to?: number[]
  target_rep_country?: string[] | undefined
}

export type SimilaritySearchInterface = {
  focalFirms: EntitySearchResult[]
  limitingClauses: FLimitingClauseType[]
  results: LawFirmResults | null
  searchParameters: SearchParameters
}

const initialSearchParameters: SearchParameters = {
  entityGeoBoundsName: 'Washington, D.C.',
  entityGeoBoundsHigh: [38.9958641, -76.909393],
  entityGeoBoundsLow: [38.7916449, -77.119759],
  entityGeoBoundsRadius: RadiusValue._0,
  entityGeoBoundsRadiusUnit: RadiusUnit.MI,
  entityCountry: [],
  primaryAttributeAuthority: [],
  primaryAttributeCountry: [],
  primaryAttributeGeoBoundsName: 'Washington, D.C.',
  primaryAttributeGeoBoundsHigh: [38.9958641, -76.909393],
  primaryAttributeGeoBoundsLow: [38.7916449, -77.119759],
  primaryAttributeGeoBoundsRadius: RadiusValue._0,
  primaryAttributeGeoBoundsRadiusUnit: RadiusUnit.MI,
  date_type: 'm',
  date_from: new Date().getFullYear() - 3,
  date_to: new Date().getFullYear(),
  filingReceivedFromCountry: [],
  filingReceivedFromPublAuth: [],
  filingReceivedFromGeoBoundsName: 'Washington, D.C.',
  filingReceivedFromGeoBoundsHigh: [38.9958641, -76.909393],
  filingReceivedFromGeoBoundsLow: [38.7916449, -77.119759],
  filingReceivedFromGeoBoundsRadius: RadiusValue._0,
  filingReceivedFromGeoBoundsRadiusUnit: RadiusUnit.MI,
  filingSentToCountry: [],
  filingSentToPublnAuth: [],
  filingSentToGeoBoundsName: 'Washington, D.C.',
  filingSentToGeoBoundsHigh: [38.9958641, -76.909393],
  filingSentToGeoBoundsLow: [38.7916449, -77.119759],
  filingSentToGeoBoundsRadius: RadiusValue._0,
  filingSentToGeoBoundsRadiusUnit: RadiusUnit.MI,
  receivedCasesApplicantDecision: true,
  sentCasesApplicantDecision: true,
  secondLevelCasesApplicantDecision: true,
  representative_type: [],
  appln_type: [],
  rep_location: [],
  nice_class: [],
  lifecycle: [],
  nice_category: [],
  verbal_element: [],
  ipc_class: [],
  techn_field_nr: [],
  sequence: [],
  grant_status: [],
  route: [],
  legal_state: [],
  relevant_count_from: [],
  relevant_count_to: [],
  applicant_country: [],
  direction: [],
  applicant_origin: [],
  applicant_url: [],
  rep_url: [],
  rep_country: [],
  publn_auth: [],
  int_appln_auth: [],
  int_decision_maker: [],
  dom_publn_auth: [],
  dom_rep_country: [],
  dom_rep_url: [],
  int_publn_auth: [],
  int_rep_country: [],
  int_rep_url: [],
  foreign_rep_url: [],
  foreign_appln_auth: [],
  foreign_publn_auth: [],
  foreign_rep_country: [],
}

const newFilters = [
  { prop: 'direction', value: FLimitingClauseType.Direction },
  { prop: 'focal_direction', value: FLimitingClauseType.Direction },
  { prop: 'target_direction', value: FLimitingClauseType.Direction },
  { prop: 'applicant_country', value: FLimitingClauseType.ApplicantCountry },
  { prop: 'focal_applicant_country', value: FLimitingClauseType.ApplicantCountry },
  { prop: 'target_applicant_country', value: FLimitingClauseType.ApplicantCountry },
  { prop: 'rep_country', value: FLimitingClauseType.RepCountry },
  { prop: 'focal_rep_country', value: FLimitingClauseType.RepCountry },
  { prop: 'target_rep_country', value: FLimitingClauseType.RepCountry },
  { prop: 'sequence', value: FLimitingClauseType.Sequence },
  { prop: 'focal_sequence', value: FLimitingClauseType.Sequence },
  { prop: 'target_sequence', value: FLimitingClauseType.Sequence },
  { prop: 'legal_state', value: FLimitingClauseType.LegalState },
  { prop: 'focal_legal_state', value: FLimitingClauseType.LegalState },
  { prop: 'target_legal_state', value: FLimitingClauseType.LegalState },
  { prop: 'grant_status', value: FLimitingClauseType.GrantStatus },
  { prop: 'focal_grant_status', value: FLimitingClauseType.GrantStatus },
  { prop: 'target_grant_status', value: FLimitingClauseType.GrantStatus },
  { prop: 'route', value: FLimitingClauseType.Route },
  { prop: 'focal_route', value: FLimitingClauseType.Route },
  { prop: 'target_route', value: FLimitingClauseType.Route },
  { prop: 'appln_type', value: FLimitingClauseType.ApplicationType },
  { prop: 'focal_appln_type', value: FLimitingClauseType.ApplicationType },
  { prop: 'target_appln_type', value: FLimitingClauseType.ApplicationType },
  { prop: 'nice_class', value: FLimitingClauseType.NiceClass },
  { prop: 'focal_nice_class', value: FLimitingClauseType.NiceClass },
  { prop: 'target_nice_class', value: FLimitingClauseType.NiceClass },
  { prop: 'nice_category', value: FLimitingClauseType.NiceCategory },
  { prop: 'focal_nice_category', value: FLimitingClauseType.NiceCategory },
  { prop: 'target_nice_category', value: FLimitingClauseType.NiceCategory },
  { prop: 'verbal_element', value: FLimitingClauseType.VerbalElement },
  { prop: 'focal_verbal_element', value: FLimitingClauseType.VerbalElement },
  { prop: 'target_verbal_element', value: FLimitingClauseType.VerbalElement },
  { prop: 'lifecycle', value: FLimitingClauseType.Lifecycle },
  { prop: 'focal_lifecycle', value: FLimitingClauseType.Lifecycle },
  { prop: 'target_lifecycle', value: FLimitingClauseType.Lifecycle },
  { prop: 'decision_maker', value: FLimitingClauseType.DecisionMaker },
  { prop: 'focal_decision_maker', value: FLimitingClauseType.DecisionMaker },
  { prop: 'target_decision_maker', value: FLimitingClauseType.DecisionMaker },
  { prop: 'int_decision_maker', value: FLimitingClauseType.InternationalDecisionMaker },
  { prop: 'focal_int_decision_maker', value: FLimitingClauseType.InternationalDecisionMaker },
  { prop: 'target_int_decision_maker', value: FLimitingClauseType.InternationalDecisionMaker },
  { prop: 'applicant_origin', value: FLimitingClauseType.ApplicantOrigin },
  { prop: 'focal_applicant_origin', value: FLimitingClauseType.ApplicantOrigin },
  { prop: 'target_applicant_origin', value: FLimitingClauseType.ApplicantOrigin },
  { prop: 'applicant_url', value: FLimitingClauseType.ApplicantUrl },
  { prop: 'focal_applicant_url', value: FLimitingClauseType.ApplicantUrl },
  { prop: 'target_applicant_url', value: FLimitingClauseType.ApplicantUrl },
  { prop: 'int_rep_url', value: FLimitingClauseType.InternationalRepUrl },
  { prop: 'focal_int_rep_url', value: FLimitingClauseType.InternationalRepUrl },
  { prop: 'target_int_rep_url', value: FLimitingClauseType.InternationalRepUrl },
  { prop: 'int_appln_auth', value: FLimitingClauseType.InternationalApplnAuth },
  { prop: 'focal_int_appln_auth', value: FLimitingClauseType.InternationalApplnAuth },
  { prop: 'target_int_appln_auth', value: FLimitingClauseType.InternationalApplnAuth },
  { prop: 'int_publn_auth', value: FLimitingClauseType.InternationalPublnAuth },
  { prop: 'focal_int_publn_auth', value: FLimitingClauseType.InternationalPublnAuth },
  { prop: 'target_int_publn_auth', value: FLimitingClauseType.InternationalPublnAuth },
  { prop: 'int_rep_country', value: FLimitingClauseType.InternationalRepCountry },
  { prop: 'focal_int_rep_country', value: FLimitingClauseType.InternationalRepCountry },
  { prop: 'target_int_rep_country', value: FLimitingClauseType.InternationalRepCountry },
  { prop: 'dom_rep_url', value: FLimitingClauseType.DomesticRepUrl },
  { prop: 'focal_dom_rep_url', value: FLimitingClauseType.DomesticRepUrl },
  { prop: 'target_dom_rep_url', value: FLimitingClauseType.DomesticRepUrl },
  { prop: 'dom_appln_auth', value: FLimitingClauseType.DomesticApplnAuth },
  { prop: 'focal_dom_appln_auth', value: FLimitingClauseType.DomesticApplnAuth },
  { prop: 'target_dom_appln_auth', value: FLimitingClauseType.DomesticApplnAuth },
  { prop: 'dom_publn_auth', value: FLimitingClauseType.DomesticPublnAuth },
  { prop: 'focal_dom_publn_auth', value: FLimitingClauseType.DomesticPublnAuth },
  { prop: 'target_dom_publn_auth', value: FLimitingClauseType.DomesticPublnAuth },
  { prop: 'dom_rep_country', value: FLimitingClauseType.DomesticRepCountry },
  { prop: 'focal_dom_rep_country', value: FLimitingClauseType.DomesticRepCountry },
  { prop: 'target_dom_rep_country', value: FLimitingClauseType.DomesticRepCountry },
  { prop: 'foreign_rep_url', value: FLimitingClauseType.ForeignRepUrl },
  { prop: 'focal_foreign_rep_url', value: FLimitingClauseType.ForeignRepUrl },
  { prop: 'target_foreign_rep_url', value: FLimitingClauseType.ForeignRepUrl },
  { prop: 'foreign_appln_auth', value: FLimitingClauseType.ForeignApplnAuth },
  { prop: 'focal_foreign_appln_auth', value: FLimitingClauseType.ForeignApplnAuth },
  { prop: 'target_foreign_appln_auth', value: FLimitingClauseType.ForeignApplnAuth },
  { prop: 'foreign_publn_auth', value: FLimitingClauseType.ForeignPublnAuth },
  { prop: 'focal_foreign_publn_auth', value: FLimitingClauseType.ForeignPublnAuth },
  { prop: 'target_foreign_publn_auth', value: FLimitingClauseType.ForeignPublnAuth },
  { prop: 'foreign_rep_country', value: FLimitingClauseType.ForeignRepCountry },
  { prop: 'focal_foreign_rep_country', value: FLimitingClauseType.ForeignRepCountry },
  { prop: 'target_foreign_rep_country', value: FLimitingClauseType.ForeignRepCountry },
  { prop: 'representative_type', value: FLimitingClauseType.RepresentativeType },
  { prop: 'focal_representative_type', value: FLimitingClauseType.RepresentativeType },
  { prop: 'target_representative_type', value: FLimitingClauseType.RepresentativeType },
  { prop: 'publn_auth', value: FLimitingClauseType.PublnAuth },
  { prop: 'ipc_class', value: FLimitingClauseType.IPC },
  { prop: 'focal_publn_auth', value: FLimitingClauseType.PublnAuth },
  { prop: 'target_publn_auth', value: FLimitingClauseType.PublnAuth },
  { prop: 'techn_field_nr', value: FLimitingClauseType.TechnField },
  { prop: 'focal_techn_field_nr', value: FLimitingClauseType.TechnField },
  { prop: 'target_techn_field_nr', value: FLimitingClauseType.TechnField },
  { prop: 'relevant_count_from', value: FLimitingClauseType.RelevantCountFrom },
  { prop: 'relevant_count_to', value: FLimitingClauseType.RelevantCountTo },
]

export class SimilarityAgentsSearchStore implements SimilaritySearchInterface {
  private rootStore: RootStoreInterface
  focalFirms: EntitySearchResult[] = []
  limitingClauses: FLimitingClauseType[] = []
  results: LawFirmResults | null = null
  searchParameters = initialSearchParameters
  matchedEntity: EntitySearchResult | null = null

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get serverFilters() {
    const filter: ServerFilters = {
      family_type: this.rootStore.applicationStore.familyType,
      ...(this.searchParameters.rep_country.length && { target_rep_country: this.searchParameters.rep_country }),
      date_from: [this.searchParameters.date_from.toString()],
      date_to: [this.searchParameters.date_to.toString()],
      date_type: [this.searchParameters.date_type.toUpperCase() as DateType],
      focal_rep_url: this.focalFirms.map((el) => el.value),
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.limitingClauses.forEach((el) => (filter['target_' + el] = this.searchParameters[el]))

    if ((filter?.target_ipc_class?.length || 0) > 0) {
      filter.target_ipc_1 = []
      filter.target_ipc_3 = []
      filter.target_ipc_4 = []
      filter.target_ipc_8 = []
      for (const entry of filter?.target_ipc_class || []) {
        if (entry?.length === 1 || entry?.length === 2) {
          filter.target_ipc_1.push(entry)
        } else if (entry?.length === 3 || (entry.includes('!') && entry?.length === 4)) {
          filter.target_ipc_3.push(entry)
        } else if (entry?.length === 4 || entry?.length === 5) {
          filter.target_ipc_4.push(entry)
        } else {
          filter.target_ipc_8.push(entry)
        }
      }
      delete filter.target_ipc_class
    }

    // Relevant Count filter
    if (
      this.limitingClauses.includes(FLimitingClauseType.RelevantCountFrom) ||
      this.limitingClauses.includes(FLimitingClauseType.CaseRelevantCountFrom)
    ) {
      filter.target_relevant_count_from = this.searchParameters.relevant_count_from?.map((entry) => Number(entry))
      filter.target_relevant_count_to = this.searchParameters.relevant_count_to?.map((entry) => Number(entry))
    }

    return _.omitBy(filter, (value) => (typeof value === 'object' ? _.isEmpty(value) : value === undefined))
  }

  setFocalFirms = (focalFirms: EntitySearchResult[]) => {
    this.focalFirms = focalFirms
  }
  setLimitingClauses = (clauses: FLimitingClauseType[]) => (this.limitingClauses = clauses)
  setResults = (results: LawFirmResults | null) => (this.results = results)

  setSearchParameter = (searchParameter: {}) => {
    this.searchParameters = Object.assign({}, this.searchParameters, searchParameter)
  }

  unsetSearchParameter = (searchParameter: string) => {
    this.searchParameters[searchParameter] = initialSearchParameters[searchParameter] as never
  }

  setMatchedEntity = (entity: EntitySearchResult | null) => (this.matchedEntity = entity)

  resetSearchUI = () => {
    this.limitingClauses = []
    this.searchParameters = initialSearchParameters

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)

    const familyType = urlSearchParams.get('familyType')
    if (familyType) this.rootStore.applicationStore.setFamilyType(familyType.split(',') as FamilyType[])

    const limitingClauses = urlSearchParams.get('limitingClauses')
    if (limitingClauses) this.limitingClauses = limitingClauses.split(',') as FLimitingClauseType[]

    this.searchParameters.date_from = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.date_from

    this.searchParameters.date_to = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.date_to

    this.searchParameters.date_type = urlSearchParams.get('date_type') || initialSearchParameters.date_type

    this.searchParameters.group_search = urlSearchParams.get('group_search') ? true : undefined

    // Relevant Count
    if (
      this.limitingClauses.includes(FLimitingClauseType.RelevantCountFrom) ||
      this.limitingClauses.includes(FLimitingClauseType.CaseRelevantCountFrom)
    ) {
      this.searchParameters.relevant_count_from =
        arrayFromString(urlSearchParams.get('target_relevant_count_from'))?.map((entry) => Number(entry)) ||
        initialSearchParameters.relevant_count_from
      this.searchParameters.relevant_count_to =
        arrayFromString(urlSearchParams.get('target_relevant_count_to'))?.map((entry) => Number(entry)) ||
        initialSearchParameters.relevant_count_to
    }

    newFilters.forEach((el) => {
      if (this.limitingClauses.includes(el.value)) {
        this.searchParameters[el.prop] =
          arrayFromString(urlSearchParams.get('target_' + el.prop)) || initialSearchParameters[el.prop]
      }
    })

    return this.serverFilters
  }

  updateSearchParamsFromFilters = (filters: ServerFilters) => {
    const familyType = this.rootStore.applicationStore.familyType
    const language = `locale=${this.rootStore.applicationStore.language}`
    const searchStrings: string[] = [language]

    if (this.limitingClauses.length) searchStrings.push(`limitingClauses=${this.limitingClauses}`)

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) searchStrings.push(`${key}=${value && value.join(',')}`)
      else searchStrings.push(`${key}=${value}`)
    })

    if (this.rootStore.applicationStore.resultsGrouping === 'group') {
      searchStrings.push('group_search=true')
    }

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?familyType=${familyType}&${searchStrings.join('&')}`)
  }

  getSearchParamsFromFilters = (filters: ServerFilters) => {
    const familyType = this.rootStore.applicationStore.familyType
    const familyTypeString = `familyType=${familyType}`
    const language = `locale=${this.rootStore.applicationStore.language}`
    const searchStrings: string[] = [familyTypeString, language]

    if (this.limitingClauses.length) searchStrings.push(`limitingClauses=${this.limitingClauses}`)

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) searchStrings.push(`${key}=${value && value.join(',')}`)
      else searchStrings.push(`${key}=${value}`)
    })

    if (this.rootStore.applicationStore.resultsGrouping === 'group') {
      searchStrings.push('group_search=true')
    }

    return searchStrings.join('&')
  }
}
