export const primaryGreen = '#96c347'
export const primaryGreenHover = '#99b05c'
export const primaryGreenLight = '#d3e6b2'
export const primaryGreenVeryLight = '#f4f9ec'

export const secondaryGreen = '#24A85D'

export const primaryOrange = '#FFBF00'
export const primaryOrangeHover = ''

export const primaryBlue = '#85C1E9'
export const primaryBlueHover = ''

export const errorRed = '#9f3a38'
export const betaOrange = '#ca6f1f'

export const lightGrey = '#d9d9d9'
export const backgroundGrey = '#f3f4f5'
export const disabledGrey = '#999'

export const confirmedBlue = '#154360'
export const lightBlue = '#85c1e9'

export const white = '#fff'
export const textColor = '#4C4C4C'
export const linkColor = '#1B587E'

export const warningColor = '#FAECEA'
export const errorColor = '#A63326'
