import { cx, css } from '@emotion/css'
import { Trans } from '@lingui/react'
import checkboxBlue from '@pp/assets/icons/checkbox-blue.svg'
import checkboxGreen from '@pp/assets/icons/checkbox-green.svg'
import checkboxYellow from '@pp/assets/icons/checkbox-yellow.svg'
import Logo from '@pp/assets/ip-logo.svg'
import modalImage from '@pp/assets/pro-modal-image.png'
import { mobileMediaQuery } from '@pp/common/css/css.helper'
import {
  modalCss,
  rowCss,
  flexCss,
  image2Css,
  title2Css,
  leftColumnCss,
} from '@pp/modules/analyse/common/buy-modal/buy-modal.css'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { Grid, Modal, Image, Button } from 'semantic-ui-react'

import {
  gridCss,
  logoCss,
  ctaButtonCss,
  usersMessageCss,
  buttonMarginRightCss,
  buttonRowCss,
  container,
  styles,
} from './styles'

type Props = { open: boolean }

function ProModal({ open }: Props) {
  const { applicationStore } = useStores()
  const isMobile = useMediaQuery(mobileMediaQuery)

  const onModalClose = () => {
    document.body.className = document.body.className
      .split(' ')
      .filter((bodyClass) => bodyClass !== 'body-scrollable')
      .join(' ')
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    applicationStore.setProModal({ open: false, version: '' })
  }

  return (
    <Modal open={open} dimmer className={modalCss(isMobile)} onClose={onModalClose}>
      <Grid className={gridCss}>
        <Grid.Row className={rowCss}>
          <Grid.Column width={16}>
            <Image src={Logo} className={logoCss(isMobile)} alt="logo" />
            <span className={title2Css(isMobile)}>
              Unlock the Full Potential of Your Workflow with <b>IP Pilot Pro</b>!
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={rowCss}>
          <Grid.Column mobile={16} tablet={6} computer={6} className={leftColumnCss(isMobile)}>
            <div style={container(isMobile)}>
              <p style={styles.title}>
                Upgrade to IP Pilot Pro and gain access to powerful tools designed to fuel your business development
                with data-driven insights and analytics.
              </p>
              <div>
                <div style={styles.row}>
                  <img src={checkboxYellow} alt="checkbox-1" style={styles.checkbox} />
                  <p style={styles.paragraph}>
                    <span style={styles.title1}>MAKE LIFE EASIER</span> – Streamline workflows and save time.
                  </p>
                </div>
                <div style={styles.row}>
                  <img src={checkboxGreen} alt="checkbox-2" style={styles.checkbox} />
                  <p style={styles.paragraph}>
                    <span style={styles.title2}>DIVE DEEPER</span> – Gain richer insights for smarter decisions.
                  </p>
                </div>
                <div style={styles.row}>
                  <img src={checkboxBlue} alt="checkbox-3" style={styles.checkbox} />
                  <p style={styles.paragraph}>
                    <span style={styles.title3}>CONNECT</span> – Strengthen relationships with key partners.
                  </p>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={10} className={leftColumnCss(isMobile)}>
            <div className={image2Css}>
              <Image
                className={css`
                  width: 100%;
                `}
                src={modalImage}
                alt="pro-modal-image"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={rowCss}>
          <Grid.Column width={16} className={flexCss}>
            <div className={buttonRowCss}>
              <Button
                secondary
                size="big"
                className={cx(ctaButtonCss, buttonMarginRightCss)}
                onClick={() => window.open('https://www.ip-pilot.com/en/products/ip-pilot-pro', '_blank')}
              >
                <Trans id="homepage.standardVersion.learnMore.button" />
              </Button>
              <Button
                primary
                size="big"
                className={ctaButtonCss}
                onClick={() =>
                  window.open('https://www.ip-pilot.com/en/get-access/pro-version-register-interest', '_blank')
                }
              >
                <Trans id="homepage.standardVersion.upgrade.button" />
              </Button>
              <p className={usersMessageCss(isMobile)}>
                🏆 <i>Hundreds of users utilize PRO features every day!</i>
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default ProModal
