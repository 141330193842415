import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { Label } from 'semantic-ui-react'

// TODO: replace newLabel with proLabel across components

const proLabel = (margin = '0 0 0 4px') => (
  <Label
    className={cx(
      '',
      css`
        background-color: var(--primary-color) !important;
        color: white !important;
        font-size: 8px !important;
        margin: ${margin} !important;

        &.active {
          background-color: white !important;
          color: var(--primary-color) !important;
        }
      `,
    )}
  >
    <Trans id="Pro" />
  </Label>
)

export default proLabel
