import { TCaseflow } from '@pp/common/chart/typescript/caseflow.interface'
import {
  Domestic,
  CasesByAuth,
  Country,
  EnrichedSplineChart,
  FilingsByYearAndAuth,
  IPCData,
  LegalState,
  LineChart,
  NumberOfCasesByDecisionData,
  SentAndReceivedCases,
  TechnSector,
  Alive,
  FilingsByYearAndType,
  NameValue,
  NumbersOfSentAndReceivedCasesByPartner,
} from '@pp/common/chart/typescript/chart-data.type'
import { EPValidation } from '@pp/common/components/table/ep-validations-table/ep-validations-table.type'
import { TopEntitiesByYearData } from '@pp/common/components/table/top-entity-table/top-entity-table.type'
import { ChartWidgetType } from '@pp/common/typescript/dashboard.type'
import { ServiceProvider } from '@pp/modules/analyse/common/charts/ep-validations/NumberOfValidationsByServiceProviderChart'
import { TechnicalFieldsData } from '@pp/modules/analyse/common/charts/technical-fields/TechnicalFieldsChart'

export enum RadiusUnit {
  KM = 'kilometers',
  MI = 'miles',
}

export enum RadiusValue {
  _0 = 0,
  _10 = 10,
  _20 = 20,
  _50 = 50,
  _100 = 100,
  _150 = 150,
  _200 = 200,
}

export enum FLimitingClauseType {
  Any = 'any',
  ApplicantCountry = 'applicant_country',
  ApplicantGroupUrl = 'applicant_group_url',
  ApplicantOrigin = 'applicant_origin',
  ApplicantUrl = 'applicant_url',
  ApplicationType = 'appln_type',
  CaseRelevantCountFrom = 'case_relevant_count_from',
  CaseRelevantCountTo = 'case_relevant_count_to',
  DecisionMaker = 'decision_maker',
  Direction = 'direction',
  DomesticApplnAuth = 'dom_appln_auth',
  DomesticPublnAuth = 'dom_publn_auth',
  DomesticRepCountry = 'dom_rep_country',
  DomesticRepUrl = 'dom_rep_url',
  ForeignApplnAuth = 'foreign_appln_auth',
  ForeignPublnAuth = 'foreign_publn_auth',
  ForeignRepCountry = 'foreign_rep_country',
  ForeignRepUrl = 'foreign_rep_url',
  GrantStatus = 'grant_status',
  IPC = 'ipc_class',
  InternationalApplnAuth = 'int_appln_auth',
  InternationalDecisionMaker = 'int_decision_maker',
  InternationalPublnAuth = 'int_publn_auth',
  InternationalRepCountry = 'int_rep_country',
  InternationalRepUrl = 'int_rep_url',
  LegalState = 'legal_state',
  Lifecycle = 'lifecycle',
  NiceCategory = 'nice_category',
  NiceClass = 'nice_class',
  PublnAuth = 'publn_auth',
  RelevantCountFrom = 'relevant_count_from',
  RelevantCountTo = 'relevant_count_to',
  RepCountry = 'rep_country',
  RepUrl = 'rep_url',
  RepresentativeType = 'representative_type',
  Route = 'route',
  Sequence = 'sequence',
  TechnField = 'techn_field_nr',
  VerbalElement = 'verbal_element',
}

export enum VLimitingClauseType {
  Any = 'any',
  ApplicantCountry = 'applicantCountry',
  ApplicantGroupUrl = 'applicant_group_url',
  ApplicantUrl = 'applicant_url',
  EpRepCountry = 'ep_rep_country',
  EpRepUrl = 'ep_rep_url',
  EpValApplicantCountry = 'ep_val_applicant_country',
  EpValApplicantUrl = 'ep_val_applicant_url',
  EpValForeignRepUrl = 'ep_val_foreign_rep_url',
  EpValRepCountry = 'ep_val_rep_country',
  EpValRepUrl = 'ep_val_rep_url',
  EpValType = 'ep_val_type',
  IPC = 'ipc_class',
  LawfirmRepresentedEPFiling = 'lawfirmRepresentedEPFiling',
  LimitNumberOfRelevantFilings = 'val_relevant_count_from',
  ReceivingLawFirmChosenBy = 'receivingLawFirmChosenBy',
  RepresentativeType = 'representativeType',
  TechnField = 'techn_field_nr',
  ValDecisionMaker = 'val_decision_maker',
  ValInhouse = 'val_inhouse',
  ValRepCountry = 'val_rep_country',
  ValRepUrl = 'val_rep_url',
  ValServiceProvider = 'val_service_provider',
  ValType = 'val_type',
  ValidatedByLawfirmFromCountry = 'validatedByLawfirmFromCountry',
  ValidationApplicationAuthority = 'val_appln_auth',
  ValidationServiceProvider = 'validationServiceProvider',
}

export interface CommonSearchQuery {
  dateFrom: string
  dateTo: string
  entityCountry: string[]
  filingIPC: string[]
  filingTechnField: number[]
  relevantCountFrom: number | undefined
  relevantCountTo: number | undefined
  representativeType: string | undefined
}

export interface lfExchangeSentReceivedAuth {
  received: CasesByAuth[]
  sent: CasesByAuth[]
}

export interface lfExchangeSentReceivedRepCountry {
  received: Country[]
  sent: Country[]
}

export type AdvancedSearchCharts = {
  apExchangeDecisionMaker?: NumberOfCasesByDecisionData | null
  apExchangeFirstLevelAuth?: CasesByAuth[] | null
  apExchangeFirstLevelRep?: TopEntitiesByYearData[] | null
  apExchangeFirstLevelRepCountry?: Country[] | null
  apExchangeOutboundCaseFlow?: TCaseflow | null
  apExchangeSecondLevelAuth?: Array<{ auth: string; count: number }> | null
  apExchangeSecondLevelRep?: TopEntitiesByYearData[] | null
  apExchangeSecondLevelRepCountry?: Country[] | null
  apOverviewFilingRoute?: { notpct: number; pct: number } | null
  apOverviewFilingsByYear?: LineChart[] | null
  apOverviewGrantStatus?: { granted: number; notgranted: number } | null
  apOverviewIPC?: IPCData[] | null
  apOverviewLegalState?: LegalState[] | null
  apOverviewLifecycle?: Alive | null
  apOverviewLocation?: NameValue[] | null
  apOverviewNiceClass?: NameValue[] | null
  apOverviewPubAuthorityByYear?: FilingsByYearAndAuth[] | null
  apOverviewRepByYear?: TopEntitiesByYearData[] | null
  apOverviewRepCountry?: Country[] | null
  apOverviewRepType?: { external: number; internal: number } | null
  apOverviewRightCategory?: NameValue[] | null
  apOverviewRightTypeByYear?: FilingsByYearAndAuth[] | null
  'apOverviewRightTypeByYear&apOverviewFilingsByYear'?: null
  apOverviewTechnField?: TechnicalFieldsData[] | null
  apOverviewTechnSector?: TechnSector[] | null
  apOverviewTypeOfFiling?: FilingsByYearAndType[] | null
  apOverviewVerbalElements?: NameValue[] | null
  apValidationDecisionMaker?: { a: number; lf: number } | null
  apValidationEpRep?: TopEntitiesByYearData[] | null
  apValidationEpRepByYear?: EnrichedSplineChart[] | null
  apValidationEpRepCountry?: Country[]
  apValidationOutboundVal?: TCaseflow | null
  apValidationServiceProvider?: Array<{ name: ServiceProvider; value: number }> | null
  apValidationValAuthorityByYear?: FilingsByYearAndAuth[] | null
  apValidationValRep?: TopEntitiesByYearData[] | null
  apValidationValRepByYear?: EnrichedSplineChart[] | null
  apValidationValRepCountry?: Country[]
  lfExchangeDecisionMakerReceived?: NumberOfCasesByDecisionData | null
  lfExchangeDecisionMakerSent?: NumberOfCasesByDecisionData | null
  lfExchangeInboundCaseFlow?: TCaseflow | null
  lfExchangeIntCaseExchange?: SentAndReceivedCases | null
  lfExchangeOutboundCaseFlow?: TCaseflow | null
  lfExchangePartners?: NumbersOfSentAndReceivedCasesByPartner[] | null
  lfExchangePartnersByYear?: EnrichedSplineChart[] | null
  lfExchangeReceivingRepCountryByYear?: EnrichedSplineChart[] | null
  lfExchangeSendingRepCountryByYear?: EnrichedSplineChart[] | null
  lfExchangeSentReceivedAuth?: lfExchangeSentReceivedAuth | null
  lfExchangeSentReceivedRepCountry?: lfExchangeSentReceivedRepCountry | null
  lfOverviewApplicantOrigin?: Domestic | null
  lfOverviewClientCountry?: Country[] | null
  lfOverviewClients?: TopEntitiesByYearData[] | null
  lfOverviewClientsByYear?: TopEntitiesByYearData[] | null
  lfOverviewFilingRoute?: { notpct: number; pct: number } | null
  lfOverviewFilingsByYear?: LineChart[] | null
  lfOverviewGrantStatus?: { granted: number; notgranted: number } | null
  lfOverviewIPC?: IPCData[] | null
  lfOverviewLegalState?: LegalState[] | null
  lfOverviewLifecycle?: Alive | null
  lfOverviewLocation?: NameValue[] | null
  lfOverviewNiceClass?: NameValue[] | null
  lfOverviewPubAuthorityByYear?: FilingsByYearAndAuth[] | null
  lfOverviewRightCategory?: NameValue[] | null
  lfOverviewRightTypeByYear?: FilingsByYearAndAuth[] | null
  'lfOverviewRightTypeByYear&lfOverviewFilingsByYear'?: null
  lfOverviewTechnField?: TechnicalFieldsData[] | null
  lfOverviewTechnSector?: TechnSector[] | null
  lfOverviewTypeOfFiling?: FilingsByYearAndType[] | null
  lfOverviewVerbalElements?: NameValue[] | null
  lfValidationClientCountry?: Country[] | null
  lfValidationClients?: TopEntitiesByYearData[] | null
  lfValidationDecisionMaker?: { a: number; lf: number } | null
  lfValidationEpRep?: TopEntitiesByYearData[] | null
  lfValidationEpRepByYear?: EnrichedSplineChart[] | null
  lfValidationEpRepCountry?: Country[]
  lfValidationInboundVal?: TCaseflow | null
  lfValidationLatestVal?: EPValidation[] | null
  lfValidationOutboundVal?: TCaseflow | null
  lfValidationPartners?: NumbersOfSentAndReceivedCasesByPartner[] | null
  lfValidationServiceProvider?: Array<{ name: ServiceProvider; value: number }> | null
  lfValidationType?: FilingsByYearAndAuth[] | null
  lfValidationValAuthorityByYear?: FilingsByYearAndAuth[] | null
  lfValidationValRep?: TopEntitiesByYearData[] | null
  lfValidationValRepByYear?: EnrichedSplineChart[] | null
  lfValidationValRepCountry?: Country[]
  name: string
  rep_url: string
}

export type AdvancedSearchChartsEndpoints = keyof AdvancedSearchCharts

type NewType = {
  country: string
  geoLocation: GeoLocationType[]
  isApplicant: boolean
  lfOverviewClientsByYear?: TopEntitiesByYearData[]
  lfOverviewIPC?: IPCData[]
  lfOverviewLegalState?: LegalState[][]
  lfOverviewTechnField?: TechnicalFieldsData[]
  name: string
  profile: boolean
  srRatio: string
  totalCount: number
  totalPCount: number
  totalTMCount: number
  urlIdentifier: string
}

export interface Result {
  contact: NewType
  relevantCount: number
  relevantPCount: number
  relevantTMCount: number
}

export interface GeoLocationType {
  count?: number
  lat: number
  lon: number
}

export type ParsedResult = Result & {
  __typename?: string
  contact: {
    __typename?: string
    geoLocation: GeoLocationType[]
  }
  id: string
}

export type SearchParametersProperty = keyof SearchParameters
export interface SearchParameters {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
  applicant_country: string[]
  applicant_origin: string[]
  applicant_url: string[]
  date_from: number
  date_to: number
  date_type: string
  direction: string[]
  dom_publn_auth: string[]
  dom_rep_country: string[]
  dom_rep_url: string[]
  entityCountry: string[]
  entityGeoBoundsHigh: number[]
  entityGeoBoundsLow: number[]
  entityGeoBoundsName: string
  entityGeoBoundsRadius: number
  entityGeoBoundsRadiusUnit: RadiusUnit
  filingReceivedFromCountry: string[]
  filingReceivedFromGeoBoundsHigh: number[]
  filingReceivedFromGeoBoundsLow: number[]
  filingReceivedFromGeoBoundsName: string
  filingReceivedFromGeoBoundsRadius: number
  filingReceivedFromGeoBoundsRadiusUnit: RadiusUnit
  filingReceivedFromPublAuth: string[]
  filingSentToCountry: string[]
  filingSentToGeoBoundsHigh: number[]
  filingSentToGeoBoundsLow: number[]
  filingSentToGeoBoundsName: string
  filingSentToGeoBoundsRadius: number
  filingSentToGeoBoundsRadiusUnit: RadiusUnit
  filingSentToPublnAuth: string[]
  foreign_appln_auth: string[]
  foreign_publn_auth: string[]
  foreign_rep_country: string[]
  foreign_rep_url: string[]
  grant_status: string[]
  int_decision_maker: string[]
  int_publn_auth: string[]
  int_rep_country: string[]
  int_rep_url: string[]
  ipc_class: string[]
  legal_state: string[]
  lifecycle: string[]
  primaryAttributeAuthority: string[]
  primaryAttributeCountry: string[]
  primaryAttributeGeoBoundsHigh: number[]
  primaryAttributeGeoBoundsLow: number[]
  primaryAttributeGeoBoundsName: string
  primaryAttributeGeoBoundsRadius: number
  primaryAttributeGeoBoundsRadiusUnit: RadiusUnit
  publn_auth: string[]
  receivedCasesApplicantDecision: boolean
  relevant_count_from: number[]
  relevant_count_to?: number[]
  rep_country: string[]
  rep_url: string[]
  representative_type: string[]
  route: string[]
  secondLevelCasesApplicantDecision: boolean
  sentCasesApplicantDecision: boolean
  sequence: string[]
  techn_field_nr: string[]
}

export interface VSearchParameters {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
  applicantCountry: string[]
  applicant_url: string[]
  date_from: number
  date_to: number
  date_type: string
  entityCountry: string[]
  ipc_class: string[]
  lawfirmRepresentedEPFiling: boolean
  primaryAttributeAuthority: string[]
  primaryAttributeCountry: string[]
  receivingLawFirmChosenBy: string
  relevant_count_from?: number[]
  relevant_count_to?: number[]
  representativeType: string
  techn_field_nr: string[]
  validationLawFirmCountry: string[]
  validationServiceProvider: string[]
}

export type Data = {
  combinedChartData: AdvancedSearchCharts[]
  localResults: ParsedResult[]
  selected_charts: ChartWidgetType[] | undefined
}
