import { CSSProperties } from 'react'

import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { newLabel } from '@pp/common/components/navigation/sidebar/components/sidebar-menus/insights-menu/InsightsMenu'
import { Menus } from '@pp/common/components/navigation/sidebar/components/sidebar-menus/VerticalMenus'
import { disabledStyleCss, slideOutCss, submenuCss } from '@pp/common/components/navigation/sidebar/sidebar.css'
import { disabledGrey, primaryGreen } from '@pp/common/css/colors'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { Menu } from 'semantic-ui-react'

const activeStyle: CSSProperties = {
  fontWeight: 'normal',
  color: primaryGreen,
}

export type DefaultMenuItemsProps = {
  active: string
  isMobile: boolean
  isProVersion?: boolean
  menus: Menus
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
}

const AnalyzeItems = ({ menus, minimizedSideBar, isMobile, active, onNavLinkClick }: DefaultMenuItemsProps) => {
  const { applicationStore, filterStore } = useStores()

  const handleClick = (route: string) => {
    onNavLinkClick(route)
  }

  return (
    <Menu.Menu className={cx(submenuCss(false, isMobile), !minimizedSideBar && slideOutCss(menus.analyzeMenu))}>
      <Menu.Item style={active === routes.agentsRoute ? activeStyle : {}}>
        <span
          className={active.includes(routes.agentsRoute) ? 'active' : ''}
          onClick={() => handleClick(`${routes.agentsRoute}?${filterStore.getActiveFiltersForUrl()}`)}
        >
          <div className="notch"></div>
          <Trans id="sidebar.menu.law-firms" />
        </span>
      </Menu.Item>
      <Menu.Item style={active === routes.applicantsRoute ? activeStyle : {}}>
        {applicationStore.hasApplicants ? (
          <span
            className={active.includes(routes.applicantsRoute) ? 'active' : ''}
            onClick={() => handleClick(`${routes.applicantsRoute}?${filterStore.getActiveFiltersForUrl()}`)}
          >
            <div className="notch"></div>
            <Trans id="sidebar.menu.applicants" />
          </span>
        ) : (
          <div
            className={disabledStyleCss(isMobile)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <Trans id="sidebar.menu.applicants" />
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {applicationStore.hasAttorneysProfile ? (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.attorneysRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.attorneysRoute)}
            >
              <div className="notch"></div>
              <Trans id="header.title.attorneys" />
              {!minimizedSideBar && newLabel()}
            </span>
          </div>
        ) : (
          <div
            className={disabledStyleCss(isMobile)}
            onClick={() => applicationStore.setProModal({ open: true, version: '' })}
          >
            <div className="notch"></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{ fontStyle: 'italic', color: applicationStore.hasAttorneysProfile ? 'inherit' : disabledGrey }}
              >
                <Trans id="header.title.attorneys" />
              </div>
              {newLabel()}
            </div>
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {applicationStore.hasFilingCheck ? (
          <span
            className={active.includes(routes.filingCheckRoute) ? 'active' : ''}
            onClick={() => handleClick(routes.filingCheckRoute)}
          >
            <div className="notch"></div>
            <Trans id="header.title.filing-check" />
          </span>
        ) : (
          <div
            className={disabledStyleCss(isMobile)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <div className="notch"></div>
            <Trans id="header.title.filing-check" />
          </div>
        )}
      </Menu.Item>
    </Menu.Menu>
  )
}

export default observer(AnalyzeItems)
