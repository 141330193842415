import { ChartWidgetType } from '@pp/common/typescript/dashboard.type'
import { BuilderPurpose } from '@pp/modules/analyse/common/types/app.type'

import { AgentFilter } from './agent-filter.type'
import { ApplicantFilter } from './applicant-filter.type'
import { FilterType } from '../filter.store'

export type EntityFilter = AgentFilter | ApplicantFilter
export type Geolocation = { distance?: string[]; lat?: number[]; lon?: number[] }
export type GeolocationBounds = { distance?: string[]; high: number[]; low: number[] }

type Applicant = {
  [key: string]: string[] | number[] | undefined | Geolocation
  applicant_country?: string[] | number[]
  applicant_locality?: string[] | number[]
  applicant_location?: Geolocation
  applicant_url?: string[] | number[]
}

type Validation = {
  [key: string]: string[] | number[] | undefined | boolean[]
  applicant_country?: string[] | number[]
  applicant_url?: string[] | number[]
  ep_rep_country?: string[] | number[]
  ep_rep_inhouse?: boolean[]
  ep_rep_url?: string[] | number[]
  ep_val_type?: string[] | number[]
  val_appln_auth?: string[] | number[]
  val_decision_maker?: string[] | number[]
  val_inhouse?: string[] | boolean[]
  val_rep_country?: string[] | number[]
  val_rep_url?: string[] | number[]
  val_service_provider?: string[] | number[]
  val_type?: string[] | number[]
}

export type SearchFilters = MappedFilterType & {
  [key: string]: string[] | number[] | undefined | boolean[] | Geolocation
  advanced_search_type?: string[]
  applicant_geo_bounds_distance?: number[]
  applicant_geo_bounds_distance_unit?: number[]
  applicant_geo_bounds_high?: number[]
  applicant_geo_bounds_low?: number[]
  applicant_geo_bounds_name?: string[]
  confirmed?: boolean[]
  relevant_count_from?: number[]
  relevant_count_to?: number[]
  rep_geo_bounds_distance?: number[]
  rep_geo_bounds_distance_unit?: number[]
  rep_geo_bounds_high?: number[]
  rep_geo_bounds_low?: number[]
  rep_geo_bounds_name?: string[]
  selected_charts?: ChartWidgetType[]
}

export type MappedFilters =
  | 'applicant'
  | 'applicant_location'
  | 'builder_purpose'
  | 'partial'
  | 'rep_location'
  | 'rep_url'
  | 'validation'

export type MappedFilterType = FilterType & {
  [key: string]: Applicant | Geolocation | string | boolean | Geolocation | string[] | number[] | Validation | undefined
  advanced_search_type?: Array<'LF' | 'A' | 'ATTORNEY' | 'GROUP'>
  applicant?: Applicant
  applicant_geo_bounds?: GeolocationBounds
  builder_purpose?: BuilderPurpose
  partial?: boolean
  rep_geo_bounds?: GeolocationBounds
  rep_url?: string[] | number[]
  validation?: Validation
}

export enum DecisionMakerEnum {
  A = 'A',
  LF = 'LF',
}

export type SimilarityWeights =
  | 'lawFirmTechnologyWeight'
  | 'lawFirmGeographyWeight'
  | 'lawFirmSizeWeight'
  | 'lawFirmTypeOfFilingWeight'
  | 'applicantTechnologyWeight'
  | 'applicantGeographyWeight'
  | 'applicantSizeWeight'
