export enum EntityType {
  Agent = 'agent',
  Applicant = 'applicant',
  Attorney = 'attorney',
}

export type CurrentEmployer = {
  name: string | null
  url: string | null
}

export interface EntitySearchResult {
  alternativeName?: string
  applnCount: number
  country: string
  data?: {
    currentEmployer: CurrentEmployer
    domain: string | null
    email: string | null
    firstName: string | null
    image: string | null
    jobTitle: string | null
    lastName: string | null
    linkedin: string | null
    logoUrl: string | null
    web: string | null
  }[]
  group_url: string
  id?: number
  isApplicant?: boolean
  key: string
  name: string
  profile: boolean
  rep_inhouse?: boolean
  similarity_score?: number
  tmCount: number
  validationCount?: number
  value: string
}

export interface EntitySearchResultAttorney {
  country: string
  data: {
    currentEmployer: CurrentEmployer[]
    domain: string | null
    image: string | null
    jobTitle: string | null
    linkedin: string | null
    logoUrl: string | null
    web: string | null
  }[]
  group_url?: string
  id?: number
  key: string
  name: string
  profile: boolean
  rep_inhouse?: boolean
  value: string
}
