import { useEffect, useState } from 'react'

import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/css'
import { Loader } from '@googlemaps/js-api-loader'
import ProModal from '@pp/common/components/pro-modal/ProModal'
import environment from '@pp/environments'
import Router from '@pp/routing/Router'
import { useMediaQuery } from '@react-hook/media-query'
import GA4React from 'ga-4-react'
import { createBrowserHistory } from 'history'
import { observer } from 'mobx-react-lite'
import { syncHistoryWithStore } from 'mobx-react-router'
import { Router as BrowserRouter } from 'react-router'

import AdBlockWarning from './AdBlockWarning'
import MobileMenu from './common/components/navigation/mobile-menu/MobileMenu'
import Sidebar from './common/components/navigation/sidebar/Sidebar'
import Spinner from './common/components/spinner/Spinner'
import { ipPilotUsers } from './common/constants/ip-pilot-users'
import { minimizedSidebarWidth, sidebarWidth, mobileHeaderHeight, mobileMediaQuery } from './common/css/css.helper'
import { useToken } from './common/custom-hooks/useToken.hook'
import EnvironmentWarning from './EnvironmentWarning'
import graphqlClient from './graphql/client'
import CustomI18nProvider from './i18n/CustomI18nProvider'
import BuyModal from './modules/analyse/common/buy-modal/BuyModal'
import UpgradeModal from './modules/analyse/common/upgrade-modal/UpgradeModal'
import { DeviceInfoProvider } from './modules/settings/providers/DeviceInfoProvider'
import { GeoLocationProvider } from './modules/settings/providers/GeoLocationProvider'
import { UserProvider } from './modules/settings/providers/UserProvider'
import rootStoreInstance from './store/root.store'
import { StoreProvider } from './store/StoreContext.provider'
import { useStores } from './store/useStore.hook'

const containerCss = ({ isMobile, minimizedSideBar }: { isMobile: boolean; minimizedSideBar: boolean }) => css`
  margin-left: ${isMobile ? '0' : minimizedSideBar ? minimizedSidebarWidth : sidebarWidth};
  margin-top: ${isMobile ? mobileHeaderHeight : '0'};
  transition: margin 0.6s;

  @media print {
    margin-left: 0;
  }
`

export const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, rootStoreInstance.routerStore)

const withUserTracking = (Component: JSX.Element) => {
  return (
    <UserProvider>
      <GeoLocationProvider>
        <DeviceInfoProvider>{Component}</DeviceInfoProvider>
      </GeoLocationProvider>
    </UserProvider>
  )
}

const ConditionalRouting = observer(
  ({ isMobile, isAuthenticated }: { isAuthenticated: boolean; isMobile: boolean }) => {
    const { applicationStore } = useStores()
    const { minimizedSideBar } = applicationStore

    useEffect(() => {
      applicationStore.setAuthenticated(isAuthenticated)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    return (
      <div className={containerCss({ isMobile, minimizedSideBar })}>
        <Router />
        {isAuthenticated ? <UpgradeModal open={applicationStore.modal.open} /> : <BuyModal />}
        <ProModal open={applicationStore.proModal.open} />
      </div>
    )
  },
)

function App() {
  const isMobile = useMediaQuery(mobileMediaQuery)
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0()
  const [sidebarVisible, setSidebarVisible] = useState(isMobile ? false : true)
  const { token } = useToken()
  const pathname = (window && window.location.pathname) || ''

  useEffect(() => {
    if (environment.appGAKey && isAuthenticated) {
      const ga4react = new GA4React(environment.appGAKey, {}, [], 0)
      ga4react.initialize().then(
        (ga4) => {
          ga4.pageview(pathname)
        },
        (err) => {
          console.error(err)
        },
      )
    }

    const loadGoogleMaps = async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyBjODOjJqW0fuy8O_aB8HOoKkqhuHQ4l6I',
      })

      await loader.importLibrary('places')
    }

    if (isAuthenticated) {
      loadGoogleMaps().then(() => {})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    if (
      (!isAuthenticated && !isLoading && window.location.pathname.includes('/print')) ||
      (!isAuthenticated && !isLoading && window.location.search.includes('direct_login'))
    ) {
      loginWithRedirect({ appState: { returnTo: window.location.pathname + window.location.search } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    setSidebarVisible(!isMobile)
  }, [isMobile])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  if (window.adBlockEnabled && isAuthenticated) return <AdBlockWarning />

  if (environment.name !== 'production' && user && !ipPilotUsers.includes(user.email || '')) {
    return <EnvironmentWarning />
  }

  if (isLoading) return <Spinner />

  return (
    <ApolloProvider client={graphqlClient}>
      <StoreProvider store={rootStoreInstance}>
        <CustomI18nProvider>
          <BrowserRouter history={history}>
            {isMobile ? <MobileMenu setSidebarVisible={setSidebarVisible} sidebarVisible={sidebarVisible} /> : null}
            <Sidebar isMobile={isMobile} setSidebarVisible={setSidebarVisible} sidebarVisible={sidebarVisible} />
            {isAuthenticated && token ? (
              withUserTracking(<ConditionalRouting isMobile={isMobile} isAuthenticated={isAuthenticated} />)
            ) : (
              <ConditionalRouting isMobile={isMobile} isAuthenticated={isAuthenticated} />
            )}
            {!isAuthenticated ? (
              <footer style={{ textAlign: 'center' }}>
                © Patent-Pilot GmbH 2016 - {new Date().getFullYear()} |{' '}
                <a href="https://www.ip-pilot.com/en/legal-notice/">Legal Notice</a> |{' '}
                <a href="http://www.ip-pilot.com/en/data-privacy-policy/">Data Privacy Policy</a>
              </footer>
            ) : null}
          </BrowserRouter>
        </CustomI18nProvider>
      </StoreProvider>
    </ApolloProvider>
  )
}

export default observer(App)
