import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { disabledStyleCss, slideOutCss, submenuCss } from '@pp/common/components/navigation/sidebar/sidebar.css'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { Menu } from 'semantic-ui-react'

import { DefaultMenuItemsProps } from '../analyze-menu/AnalyzeItems'

const InsightsItems = ({
  menus,
  minimizedSideBar,
  isMobile,
  active,
  onNavLinkClick,
  isProVersion,
}: DefaultMenuItemsProps) => {
  const { applicationStore } = useStores()

  const handleClick = (route: string) => {
    onNavLinkClick(route)
  }

  return (
    <Menu.Menu className={cx(submenuCss(false, isMobile), !minimizedSideBar && slideOutCss(menus.checkMenu))}>
      <Menu.Item>
        {isProVersion ? (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.similarityRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.similarityAgentsRoute)}
            >
              <div className="notch"></div>
              <Trans id="header.title.similarity" />
            </span>
          </div>
        ) : (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={disabledStyleCss(isMobile, true)}
              onClick={() => applicationStore.setProModal({ open: true, version: '' })}
            >
              <div className="notch"></div>
              <Trans id="header.title.similarity" />
            </span>
          </div>
        )}
      </Menu.Item>

      <Menu.Item>
        {isProVersion ? (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.mutualityRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.mutualityRoute)}
            >
              <div className="notch"></div>
              <Trans id="header.title.mutuality" />
            </span>
          </div>
        ) : (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={disabledStyleCss(isMobile, true)}
              onClick={() => applicationStore.setProModal({ open: true, version: '' })}
            >
              <div className="notch"></div>
              <Trans id="header.title.mutuality" />
            </span>
          </div>
        )}
      </Menu.Item>

      <Menu.Item>
        {isProVersion ? (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.techOverlapRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.techOverlapAgentsCheckRoute)}
            >
              <div className="notch"></div>
              <Trans id="sidebar.menu.techOverlap" />
            </span>
          </div>
        ) : (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={disabledStyleCss(isMobile, true)}
              onClick={() => applicationStore.setProModal({ open: true, version: '' })}
            >
              <div className="notch"></div>
              <Trans id="sidebar.menu.techOverlap" />
            </span>
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {isProVersion ? (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.comparisonRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.comparisonAgentsRoute)}
            >
              <div className="notch"></div>
              <Trans id="sidebar.menu.comparison" />
            </span>
          </div>
        ) : (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={disabledStyleCss(isMobile, true)}
              onClick={() => applicationStore.setProModal({ open: true, version: '' })}
            >
              <div className="notch"></div>
              <Trans id="sidebar.menu.comparison" />
            </span>
          </div>
        )}
      </Menu.Item>
    </Menu.Menu>
  )
}

export default observer(InsightsItems)
