import { css } from '@emotion/css'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { useStores } from '@pp/store/useStore.hook'
import { Menu } from 'semantic-ui-react'

import { AnalyzeMenu, DashboardMenu, InsightsMenu, ResourcesMenu, SearchMenu, ToolsMenu } from '..'

const menuItem = css`
  ${increaseSpecificity(`
  font-size: 1em;
  padding: 0;
  display: flex;
  flex-direction: column;

  &:before {
    background: radial-gradient(50% 50% at 50% 50%, #E6E6E6 0%, rgba(230, 230, 230, 0) 100%);
    position: absolute !important;
    content: '';
    top: 0%;
    left: 0px;
    width: 100% !important;
    height: 1px !important;
  }

  &:last-child {
    &:after {
      position: absolute;
      content: '';
      top: 100%;
      left: 0px;
      width: 100%;
      height: 1px;
      background: radial-gradient(50% 50% at 50% 50%, #E6E6E6 0%, rgba(230, 230, 230, 0) 100%);
    }
  }
`)}
`

export type Menus = {
  analyzeMenu: boolean
  checkMenu: boolean
  resourceSubmenu: boolean
  searchMenu: boolean
  toolsMenu: boolean
}

type Props = {
  active: string
  isMobile: boolean
  isSmallHeight?: boolean
  menus: Menus
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
  setShowMenus: React.Dispatch<React.SetStateAction<Menus>>
}

const VerticalMenus = ({
  menus,
  setShowMenus,
  isMobile,
  minimizedSideBar,
  active,
  onNavLinkClick,
  isSmallHeight,
}: Props) => {
  const { applicationStore } = useStores()

  return (
    <>
      <Menu.Item className={menuItem}>
        <DashboardMenu
          active={active}
          onNavLinkClick={onNavLinkClick}
          minimizedSideBar={minimizedSideBar}
          menus={menus}
          isMobile={isMobile}
        />
      </Menu.Item>
      <Menu.Item className={menuItem}>
        <AnalyzeMenu
          active={active}
          minimizedSideBar={minimizedSideBar}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
          isSmallHeight={isSmallHeight}
          toggleSidebar={applicationStore.toggleMinimizedSidebar}
          onNavLinkClick={onNavLinkClick}
        />
      </Menu.Item>
      <Menu.Item className={menuItem}>
        <SearchMenu
          active={active}
          minimizedSideBar={minimizedSideBar}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
          isSmallHeight={isSmallHeight}
          onNavLinkClick={onNavLinkClick}
        />
      </Menu.Item>
      <Menu.Item className={menuItem}>
        <InsightsMenu
          active={active}
          minimizedSideBar={minimizedSideBar}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
          isSmallHeight={isSmallHeight}
          toggleSidebar={applicationStore.toggleMinimizedSidebar}
          onNavLinkClick={onNavLinkClick}
          isProVersion={
            applicationStore.hasSimilarity &&
            applicationStore.hasMutuality &&
            applicationStore.hasTechOverlap &&
            applicationStore.hasComparison
          }
        />
      </Menu.Item>
      <Menu.Item className={menuItem}>
        <ToolsMenu
          active={active}
          minimizedSideBar={minimizedSideBar}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
          isSmallHeight={isSmallHeight}
          toggleSidebar={applicationStore.toggleMinimizedSidebar}
          onNavLinkClick={onNavLinkClick}
          isProVersion={applicationStore.hasExcelAutofill && applicationStore.hasCustomCharts}
        />
      </Menu.Item>
      <Menu.Item className={menuItem}>
        <ResourcesMenu
          active={active}
          minimizedSideBar={minimizedSideBar}
          menus={menus}
          setShowMenus={setShowMenus}
          isMobile={isMobile}
          isSmallHeight={isSmallHeight}
          onNavLinkClick={onNavLinkClick}
        />
      </Menu.Item>
    </>
  )
}

export default VerticalMenus
